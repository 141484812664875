import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import Share from 'components/Share'
import TransitionLink from 'components/TransitionLink'
import PostInfo from 'components/PostInfo'
import { sizes } from 'styles'

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 20px;

  @media ${sizes.tabletPortrait} {
    margin-bottom: 0;
  }
`

const StyledImage = styled(Img)`
  width: 100%;
  border-radius: 5px;
  object-fit: cover;

  > picture > img {
    transform: scale3d(1, 1, 1);
    transition: transform 400ms !important;

    :hover {
      transform: scale3d(1.05, 1.05, 1);
    }
  }
`

const StyledLink = styled(TransitionLink)`
  text-decoration: none;
  color: inherit;

  :hover {
    opacity: 1;
  }
`

const Title = styled.h3`
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 26px;
  line-height: 30px;
  color: ${({ theme }) => theme.color.textSecondary};

  @media ${sizes.desktop} {
    margin-bottom: 15px;
  }
`

const Author = styled.div`
  margin-bottom: 10px;

  @media ${sizes.desktop} {
    margin-bottom: 0;
  }
`

const StyledBlogPostListItem = styled.div`
  padding-top: 25px;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  > :first-child {
    width: 87%;
  }

  @media ${sizes.tabletPortrait} {
    flex-direction: row;

    > :first-child {
      flex: 4;
      width: 100%;
      margin-right: 25px;
    }

    > :nth-child(2) {
      flex: 5;
    }
  }

  @media ${sizes.tabletLandscape} {
    flex-direction: column;

    > :first-child {
      width: 80%;
      margin-bottom: 30px;
    }
  }

  @media ${sizes.desktop} {
    flex-direction: row;
    > :first-child {
      flex: initial;
      width: 335px;
      margin-bottom: 0;
    }
  }
`

const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  line-height: 24px;

  > p {
    margin: 10px 0;
  }
`

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.color.tertiary};
  opacity: 0.3;
`

const StyledPostInfo = styled(PostInfo)`
  margin-bottom: 15px;
`

const BlogPostListItem = ({ blogPost, className }) => {
  const {
    title,
    slug,
    author,
    publishedOn,
    tags,
    featuredImage,
    readingTime,
  } = blogPost

  return (
    <>
      <Divider className={className} />
      <StyledBlogPostListItem>
        <StyledLink to={`/blog/${slug}/`}>
          {featuredImage && (
            <ImageContainer>
              <StyledImage
                fluid={{
                  ...featuredImage.fluid,
                  aspectRatio: 4 / 3,
                }}
              />
            </ImageContainer>
          )}
        </StyledLink>
        <Info>
          <div>
            <StyledLink to={`/blog/${slug}/`}>
              <Title>{title}</Title>
            </StyledLink>
            {author && (
              <Author>by {author.map((x) => x.name).join(', ')}</Author>
            )}
          </div>
          <div>
            <StyledPostInfo
              publishedOn={publishedOn}
              tags={tags}
              readingTime={readingTime}
            />
            <Share
              shareTitle={title}
              shareLink={
                typeof window !== 'undefined'
                  ? `${window.location.protocol}//${window.location.host}/blog/${slug}`
                  : ''
              }
            />
          </div>
        </Info>
      </StyledBlogPostListItem>
    </>
  )
}

export default BlogPostListItem
