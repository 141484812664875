import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { Highlight, Snippet } from 'react-instantsearch-hooks-web'
import TransitionLink from 'components/TransitionLink'
import SquareImage from 'components/SquareImage'
import PostInfo from 'components/PostInfo'
import { sizes } from 'styles'

const StyledLink = styled(TransitionLink)`
  text-decoration: none;
  color: inherit;

  > h3 {
    margin-top: 0;
    margin-bottom: 15px;
    font-size: 26px;
    line-height: 30px;
    color: ${({ theme }) => theme.color.textSecondary};
  }
`

const Container = styled.div`
  .ais-Highlight-highlighted,
  .ais-Snippet-highlighted {
    background-color: #c0c0fb;
  }
`

const StyledPodcastEpisodeSearchResult = styled.div`
  padding-top: 15px;
  display: flex;
  flex-direction: column;

  > :first-child {
    width: 77%;
    margin-bottom: 30px;
  }

  @media ${sizes.tabletPortrait} {
    flex-direction: row;

    > :first-child {
      width: 110px;
      margin-right: 30px;
      margin-bottom: 0;
    }

    > :nth-child(2) {
      flex: 5;
    }
  }

  @media ${sizes.tabletLandscape} {
    > :first-child {
      width: 130px;
    }
  }

  @media ${sizes.smallDesktop} {
    flex-direction: row;

    > :first-child {
      width: 110px;
    }
  }
`

const Info = styled.div`
  line-height: 24px;

  > p {
    margin: 10px 0;
  }
`

const EpisodeContainer = styled.div`
  display: flex;
  align-items: center;

  > :last-child {
    flex: 1;
  }
`

const Episode = styled.div`
  display: flex;
  align-items: center;
`

const EpisodeNumber = styled.span`
  padding-right: 15px;
  font-size: 22px;
  font-family: ${({ theme }) => theme.font.headings};
  font-style: italic;
  color: ${({ theme }) => theme.color.tertiary};

  .highlight {
    background-color: #c0c0fb;
  }
`

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.color.tertiary};
  opacity: 0.3;
`

const Speakers = styled.div`
  margin-bottom: 10px;
`

const PodcastEpisodeSearchResult = ({ hit, className }) => {
  const {
    objectID,
    slug,
    speakers,
    publishedOn,
    tags,
    image,
    episodeNumber,
  } = hit
  const episodeDuration = useSelector(
    (state) => state.episodeDurations[objectID] || 0
  )

  const episodeNumberStr = `Ep. ${episodeNumber}`

  return (
    <>
      <EpisodeContainer className={className}>
        <Episode>
          <EpisodeNumber>{episodeNumberStr}</EpisodeNumber>
        </Episode>
        <Divider />
      </EpisodeContainer>
      <Container>
        <StyledPodcastEpisodeSearchResult>
          <SquareImage image={image} />
          <Info>
            <StyledLink to={`/episode/${slug}`}>
              <h3>
                <Highlight attribute="title" hit={hit} />
              </h3>
            </StyledLink>
            {speakers && (
              <Speakers>
                <Highlight attribute="speakers" hit={hit} />
              </Speakers>
            )}
            <PostInfo
              publishedOn={publishedOn}
              tags={tags}
              episodeDuration={episodeDuration}
            />
          </Info>
        </StyledPodcastEpisodeSearchResult>
        <p>
          <Highlight attribute="description" hit={hit} />
        </p>
        <p>
          <Snippet attribute="bodyText" hit={hit} />
        </p>
      </Container>
    </>
  )
}

export default PodcastEpisodeSearchResult
