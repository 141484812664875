import React from 'react'
import styled from 'styled-components'
import { Highlight, Snippet } from 'react-instantsearch-hooks-web'
import Img from 'gatsby-image'
import TransitionLink from 'components/TransitionLink'
import PostInfo from 'components/PostInfo'
import { sizes } from 'styles'

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 20px;

  @media ${sizes.tabletPortrait} {
    width: 180px;
    margin-bottom: 0;
    margin-right: 20px;
  }
`

const StyledImage = styled(Img)`
  width: 100%;
  border-radius: 5px;
  object-fit: cover;

  > picture > img {
    transform: scale3d(1, 1, 1);
    transition: transform 400ms !important;

    :hover {
      transform: scale3d(1.05, 1.05, 1);
    }
  }
`

const StyledLink = styled(TransitionLink)`
  text-decoration: none;
  color: inherit;

  :hover {
    opacity: 1;
  }
`

const Title = styled.h3`
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 26px;
  line-height: 30px;
  color: ${({ theme }) => theme.color.textSecondary};

  @media ${sizes.desktop} {
    margin-bottom: 15px;
  }
`

const Author = styled.div`
  margin-bottom: 10px;
`

const StyledBlogPostListItem = styled.div`
  padding-top: 25px;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  .ais-Highlight-highlighted,
  .ais-Snippet-highlighted {
    background-color: #c0c0fb;
  }
`

const Info = styled.div`
  display: flex;
  flex-direction: column;

  @media ${sizes.tabletPortrait} {
    flex-direction: row;
  }
`

const Text = styled.div`
  line-height: 24px;

  @media ${sizes.tabletPortrait} {
    margin-top: 20px;
  }
`

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.color.tertiary};
  opacity: 0.3;
`

const StyledPostInfo = styled(PostInfo)`
  margin-bottom: 15px;
`

const PageHit = ({ hit, className }) => {
  return (
    <>
      <Divider className={className} />
      <StyledBlogPostListItem>
        <Info>
          <StyledLink to={`/blog/${hit.slug}/`}>
            {hit.featuredImage && (
              <ImageContainer>
                <StyledImage
                  fluid={{
                    ...hit.featuredImage.fluid,
                    aspectRatio: 4 / 3,
                  }}
                />
              </ImageContainer>
            )}
          </StyledLink>
          <div>
            <StyledLink to={`/blog/${hit.slug}/`}>
              <Title>
                <Highlight attribute="title" hit={hit} tagName="mark" />
              </Title>
            </StyledLink>
            {hit.author && (
              <Author>
                <Highlight attribute="author" hit={hit} tagName="mark" />
              </Author>
            )}
            <StyledPostInfo
              publishedOn={hit.publishedOn}
              tags={hit.tags}
              readingTime={hit.readingTime}
            />
          </div>
        </Info>
        <Text>
          <Snippet attribute="content" hit={hit} tagName="mark" />
        </Text>
      </StyledBlogPostListItem>
    </>
  )
}

export default PageHit
