import React from 'react'
import styled, { css } from 'styled-components'
import TransitionLink from 'components/TransitionLink'
import { ELLIPSIS } from 'hooks/usePaginationRange'

const StyledPaginationItem = styled(TransitionLink)`
  font-size: 18px;
  color: ${({ theme }) => theme.color.textSecondary};
  text-decoration: none;
  cursor: pointer;

  ${({ $isCurrent }) =>
    $isCurrent &&
    css`
      padding: 6px 12px;
      margin: 0 -4px;
      border-radius: 50%;
      color: ${({ theme }) => theme.color.primary};
      background-color: ${({ theme }) => theme.color.lightLilac};
      text-align: center;
      font-weight: bold;
    `}
`

const PaginationItem = ({
  item,
  isCurrent,
  handleSearchNavigation,
  pageLinkBasepath,
  ...restProps
}) => {
  const isEllipsis = item === ELLIPSIS
  const itemToDisplay = isEllipsis ? '…' : item + 1

  const getLink = () => {
    if (item === 0) return pageLinkBasepath
    if (isEllipsis) return window?.location?.pathname
    return `${pageLinkBasepath}${item + 1}`
  }

  return (
    <StyledPaginationItem
      {...restProps}
      to={getLink()}
      $isCurrent={isCurrent}
      $isEllipsis={isEllipsis}
      onClick={handleSearchNavigation}
    >
      {itemToDisplay}
    </StyledPaginationItem>
  )
}

export default PaginationItem
