import React from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { InstantSearch, Configure } from 'react-instantsearch-hooks-web'
import { FaCaretRight } from 'react-icons/fa'
import RichTextRenderer from 'components/RichTextRenderer'
import Seo from 'components/Seo'
import Container from 'components/Container'
import PostLayout from 'components/PostLayout'
import PageTitle from 'components/PageTitle'
import SubscribeBox from 'components/SubscribeBox'
import Button from 'components/Button'
import { AlgoliaSearchInput } from 'components/SearchInput'
import PaginatedList from 'components/PaginatedList'
import { searchClient } from 'src/helpers'
import { playAll } from 'state/actions'
import { sizes } from 'styles'

const StyledPostLayout = styled(PostLayout)`
  @media ${sizes.desktop} {
    > aside {
      padding-top: 100px;
    }

    > article {
      margin-right: 40px;
      max-width: 800px;
    }
  }
`

const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 85%;
`

const StyledSearchInput = styled(AlgoliaSearchInput)`
  margin: 8px 0 30px 0;
`

const MainContent = ({ pageContent, podcasts, paginationInfo }) => {
  const dispatch = useDispatch()
  const { title, description } = pageContent.edges[0].node

  return (
    <>
      <Info>
        <PageTitle>{title}</PageTitle>
        <RichTextRenderer richText={description.json} />
      </Info>
      <InstantSearch
        searchClient={searchClient}
        indexName={
          process.env.GATSBY_ENV === 'production'
            ? 'episodes'
            : 'staging_episodes'
        }
      >
        <Info>
          <Configure hitsPerPage={10} />
          <StyledSearchInput placeholder="Search from the podcast..." />
        </Info>
        <Button
          variant="secondary"
          onClick={() => {
            dispatch(playAll())
          }}
        >
          Play all
          <FaCaretRight size={16} />
        </Button>
        <PaginatedList
          itemType="podcastEpisodes"
          items={podcasts}
          currentPage={paginationInfo.currentPage}
          numPages={paginationInfo.numPages}
          pageLinkBasepath={paginationInfo.pageLinkBasepath}
          nextPageLink={paginationInfo.nextPageLink}
          prevPageLink={paginationInfo.prevPageLink}
        />
      </InstantSearch>
    </>
  )
}

const EpisodeList = ({ data, paginationInfo }) => {
  const { allContentfulPodcastPage, allContentfulPodcastEpisode } = data

  const AsideContent = () => {
    return <SubscribeBox />
  }

  return (
    <Container>
      <Seo
        title="Building Remotely Podcast"
        image={{ src: undefined, width: 1600, height: 900 }}
        description={undefined}
      />
      <StyledPostLayout
        mainContent={
          <MainContent
            podcasts={allContentfulPodcastEpisode}
            pageContent={allContentfulPodcastPage}
            paginationInfo={paginationInfo}
          />
        }
        asideContent={<AsideContent />}
      />
    </Container>
  )
}

export default EpisodeList
