import React from 'react'
import styled, { css } from 'styled-components'
import { useTrail, animated, config } from 'react-spring'
import BlogPostListItem from 'components/BlogPostListItem'
import PodcastListItem from 'components/PodcastListItem'
import Pagination, { SearchPagination } from 'components/Pagination'
import BlogPostSearchResultAlgolia from 'components/BlogPostSearchResultAlgolia'
import { useSearchBox, useHits } from 'react-instantsearch-hooks-web'
import PodcastEpisodeSearchResultAlgolia from '../PodcastEpisodeSearchResultAlgolia'

const ListItem = styled(animated.div)`
  margin-top: 40px;
`

const StyledPagination = styled(Pagination)`
  margin-top: 100px;
`

const SearchResults = styled.div`
  min-height: 100vh;
`

const StyledSearchPagination = styled(SearchPagination)`
  margin-top: 100px;
  ${({ $hidden }) =>
    $hidden &&
    css`
      display: none;
    `}
`

const AnimatedList = ({ items, listType, itemType }) => {
  const getListElement = (item, itemType) => {
    if (itemType === 'blogPosts') {
      return listType === 'search' ? (
        <BlogPostSearchResultAlgolia hit={item} />
      ) : (
        <BlogPostListItem blogPost={item} />
      )
    }
    if (itemType === 'podcastEpisodes') {
      if (listType === 'search') {
        return <PodcastEpisodeSearchResultAlgolia hit={item} />
      }
      return <PodcastListItem episodeId={item?.id} />
    }
  }

  const trail = useTrail(items.length, {
    config: config.stiff,
    delay: 300,
    opacity: 1,
    transform: 'translate3d(0px, 0px, 0px)',
    from: { opacity: 0, transform: 'translate3d(0px, 20px, 0px)' },
  })

  return (
    <>
      {trail.map((props, i) => {
        const item = items[i]
        return (
          <ListItem key={i} style={props}>
            {getListElement(item, itemType)}
          </ListItem>
        )
      })}
    </>
  )
}

const PaginatedList = ({
  items,
  itemType,
  currentPage,
  numPages,
  pageLinkBasepath,
  nextPageLink,
  prevPageLink,
}) => {
  const { query } = useSearchBox()
  const { hits } = useHits()

  const listType = query?.length > 0 && hits?.length > 0 ? 'search' : 'default'

  if (query?.length > 0 && hits?.length <= 0) {
    return <SearchResults />
  }

  return (
    <>
      <SearchResults>
        <AnimatedList
          items={
            listType === 'search' ? hits : items.edges.map((edge) => edge.node)
          }
          itemType={itemType}
          listType={listType}
          key={listType}
        />
        <StyledSearchPagination pageLinkBasepath={pageLinkBasepath} />
        {query?.length <= 0 && (
          <StyledPagination
            currentPageIndex={currentPage - 1}
            numPages={numPages}
            pageLinkBasepath={pageLinkBasepath}
            nextPageLink={nextPageLink}
            prevPageLink={prevPageLink}
          />
        )}
      </SearchResults>
    </>
  )
}

export default PaginatedList
