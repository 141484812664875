import { useMemo } from 'react'
import { isNil } from 'ramda'

export const ELLIPSIS = 'ELLIPSIS'

const paginationRange = (currentPageIndex, numPages, numItems = 7) => {
  if (isNil(currentPageIndex) || isNil(numPages) || !numPages) return []
  const maxLength = Math.min(numPages, numItems)
  const arr = [0]

  if (numPages > 1) {
    arr.push(numPages - 1)
  }

  const isInBounds = (n) => {
    return !arr.includes(n) && n > 0 && n < numPages && arr.length < maxLength
  }

  if (isInBounds(currentPageIndex)) {
    arr.push(currentPageIndex)
  }

  let distance = 1

  while (arr.length < maxLength) {
    const candidates = [
      currentPageIndex - distance,
      currentPageIndex + distance,
    ]
    candidates.forEach((n) => isInBounds(n) && arr.push(n))
    distance++
  }

  const sorted = arr.sort((a, b) => a - b)

  const startDiff = sorted[1] - sorted[0]
  if (startDiff > 1) {
    sorted[1] = ELLIPSIS
  }

  const endDiff = sorted[sorted.length - 1] - sorted[sorted.length - 2]
  if (endDiff > 1) {
    sorted[sorted.length - 2] = ELLIPSIS
  }

  return sorted
}

const usePaginationRange = (currentPageIndex, numPages, numItems) =>
  useMemo(() => paginationRange(currentPageIndex, numPages, numItems), [
    currentPageIndex,
    numPages,
    numItems,
  ])

export default usePaginationRange
