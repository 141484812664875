import React from 'react'
import styled, { css } from 'styled-components'
import { usePagination } from 'react-instantsearch-hooks-web'
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa'
import TransitionLink from 'components/TransitionLink'
import PaginationItem from 'components/PaginationItem'
import usePaginationRange from 'hooks/usePaginationRange'
import useWindowSize from 'hooks/useWindowSize'
import { sizes, SCREEN_SIZES } from 'styles'

const StyledPagination = styled.div`
  display: flex;
  align-items: center;
`
const PaginationItems = styled.div`
  width: 100%;
  max-width: 300px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`

const ArrowWrapper = styled(TransitionLink)`
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.color.backgrounds};
  color: ${({ theme }) => theme.color.tertiary};
  text-decoration: none;

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: ${({ theme }) => theme.color.backgrounds};
      color: ${({ theme }) => theme.color.disabled};
      :hover {
        opacity: 1;
        cursor: default;
      }
    `}

  > svg {
    width: 16px;
    height: 16px;
  }

  @media ${sizes.tabletPortrait} {
    padding: 8px 12px;

    :first-child > svg {
      margin-right: 8px;
    }

    :last-child > svg {
      margin-left: 8px;
    }
  }
`

const Pagination = ({
  currentPageIndex,
  numPages,
  pageLinkBasepath,
  nextPageLink,
  prevPageLink,
  refine,
  ...restProps
}) => {
  const { width } = useWindowSize()
  const isMobile = width > SCREEN_SIZES.tabletPortrait
  const pagination = usePaginationRange(currentPageIndex, numPages)

  // this function handles pagination with algolia search results
  const handleSearchNavigation = (e, newPageIndex) => {
    if (!refine) return
    e.preventDefault()
    e.stopPropagation()
    refine(newPageIndex)
    window?.scrollTo({ top: 0, behavior: 'smooth' })
  }

  if (numPages <= 1) return null

  return (
    <StyledPagination {...restProps}>
      <ArrowWrapper
        to={prevPageLink || pageLinkBasepath}
        disabled={currentPageIndex === 0}
        onClick={(e) => {
          if (currentPageIndex === 0) {
            e.preventDefault()
            e.stopPropagation()
            return
          }
          handleSearchNavigation(e, currentPageIndex - 1)
        }}
      >
        <FaArrowLeft /> {isMobile ? 'Prev' : ''}
      </ArrowWrapper>
      <PaginationItems>
        {pagination.map((item, i) => {
          return (
            <PaginationItem
              key={i}
              item={item}
              handleSearchNavigation={(e) => handleSearchNavigation(e, item)}
              pageLinkBasepath={pageLinkBasepath}
              isCurrent={currentPageIndex === item}
            />
          )
        })}
      </PaginationItems>
      <ArrowWrapper
        to={nextPageLink || pageLinkBasepath}
        disabled={currentPageIndex === numPages - 1}
        onClick={(e) => {
          if (currentPageIndex === numPages - 1) {
            e.preventDefault()
            e.stopPropagation()
            return
          }
          handleSearchNavigation(e, currentPageIndex + 1)
        }}
      >
        {isMobile ? 'Next' : ''} <FaArrowRight />
      </ArrowWrapper>
    </StyledPagination>
  )
}

export const SearchPagination = ({ pageLinkBasepath, className }) => {
  const { currentRefinement, nbPages, refine } = usePagination()
  return (
    <Pagination
      pageLinkBasepath={pageLinkBasepath}
      currentPageIndex={currentRefinement}
      numPages={nbPages}
      refine={refine}
      className={className}
    />
  )
}

export default Pagination
