import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import debounce from 'lodash.debounce'
import { GrSearch, GrClose } from 'react-icons/gr'
import { useSearchBox } from 'react-instantsearch-hooks-web'

const InputContainer = styled.div`
  display: inline-block;
  border-bottom: 1px solid ${({ theme }) => theme.color.secondary};
  white-space: nowrap;

  > :last-child {
    cursor: pointer;
    vertical-align: middle;
  }
`

const StyledInput = styled.input`
  border: none;
  font-size: 16px;
  width: 260px;
  padding-bottom: 8px;

  ::placeholder {
    font-style: italic;
  }

  :focus {
    outline: none;
  }
`

const SearchInput = ({ placeholder, searchTerm, setSearchTerm, className }) => {
  return (
    <InputContainer className={className}>
      <StyledInput
        key="search"
        placeholder={placeholder}
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      {searchTerm?.length > 0 ? (
        <GrClose onClick={() => setSearchTerm('')} />
      ) : (
        <GrSearch />
      )}
    </InputContainer>
  )
}

export const AlgoliaSearchInput = ({ placeholder = 'Search', className }) => {
  const [value, setValue] = useState('')
  const { query, refine, clear } = useSearchBox()

  const debouncedSearch = useCallback(
    debounce((query) => {
      refine(query)
    }, 300),
    []
  )

  return (
    <InputContainer className={className}>
      <StyledInput
        placeholder={placeholder}
        value={value}
        onChange={(e) => {
          setValue(e.target.value)
          debouncedSearch(e.target.value)
        }}
      />
      {query?.length > 0 ? (
        <GrClose
          onClick={() => {
            setValue('')
            clear()
          }}
        />
      ) : (
        <GrSearch />
      )}
    </InputContainer>
  )
}

export default SearchInput
