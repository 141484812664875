import React, { useCallback } from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { BsFillCaretRightFill } from 'react-icons/bs'
import { GiPauseButton } from 'react-icons/gi'
import {
  setCurrentEpisodeId,
  setIsPlaying,
  setEpisodeCurrentProgress,
} from 'state/actions'
import TransitionLink from 'components/TransitionLink'
import PodcastLinks from 'components/PodcastLinks'
import SquareImage from 'components/SquareImage'
import PostInfo from 'components/PostInfo'
import Share from 'components/Share'
import { sizes } from 'styles'

const StyledLink = styled(TransitionLink)`
  text-decoration: none;
  color: inherit;

  > h3 {
    margin-top: 0;
    margin-bottom: 15px;
    font-size: 26px;
    line-height: 30px;
    color: ${({ theme }) => theme.color.textSecondary};
  }
`

const StyledPodcastListItem = styled.div`
  padding-top: 15px;
  display: flex;
  flex-direction: column;
  > :first-child {
    width: 77%;
    margin-bottom: 30px;
  }

  @media ${sizes.tabletPortrait} {
    flex-direction: row;

    > :first-child {
      flex: 3;
      width: 100%;
      margin-right: 30px;
    }

    > :nth-child(2) {
      flex: 5;
    }
  }

  @media ${sizes.tabletLandscape} {
    flex-direction: column;

    > :first-child {
      width: 80%;
      margin-bottom: 30px;
    }
  }

  @media ${sizes.smallDesktop} {
    flex-direction: row;

    > :first-child {
      flex: initial;
      width: 257px;
      margin-right: 30px;
    }
  }
`

const Info = styled.div`
  line-height: 24px;

  > p {
    margin: 10px 0;
  }
`

const PlayIcon = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 10px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #7addb6;
  transition: background-color 200ms, transform 200ms;
  opacity: 0.7;
  transform: translate(-50%, -50%) scale3d(1, 1, 1);
  box-shadow: ${({ theme }) => theme.shadow};

  > svg {
    width: 50px;
    height: 50px;
    fill: ${({ theme }) => theme.color.tertiary};
    transition: fill 200ms;
    filter: ${({ theme }) => `drop-shadow(${theme.shadow})`};
  }
`

const ImageOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  transition: background-color 200ms;
  cursor: pointer;
  user-select: none;

  &:hover {
    background-color: rgba(51, 51, 119, 0.6);

    > ${PlayIcon} {
      transform: translate(-50%, -50%) scale3d(1.1, 1.1, 1);
      opacity: 1;
      > svg {
        fill: ${({ theme }) => theme.color.white};
      }
    }
  }
`

const EpisodeContainer = styled.div`
  display: flex;
  align-items: center;

  > :last-child {
    flex: 1;
  }
`

const Episode = styled.div`
  display: flex;
  align-items: center;
`

const EpisodeNumber = styled.span`
  padding-right: 15px;
  font-size: 22px;
  font-family: ${({ theme }) => theme.font.headings};
  font-style: italic;
  color: ${({ theme }) => theme.color.tertiary};
`

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.color.tertiary};
  opacity: 0.3;
`

const Row = styled.div`
  display: flex;

  > :not(:first-child) {
    margin-left: 10px;
  }
`

const StyledPostInfo = styled(PostInfo)`
  margin-bottom: 15px;
`

const PodcastListItem = ({ episodeId, className }) => {
  const [
    episode,
    isPlaying,
    currentEpisodeId,
    episodeDuration,
  ] = useSelector((state) => [
    state.podcastPlaylist.find((x) => x.id === episodeId),
    state.podcastPlayer.isPlaying,
    state.podcastPlayer.currentEpisodeId,
    state.episodeDurations[episodeId] || 0,
  ])

  const dispatch = useDispatch()

  const episodeIsPlaying = currentEpisodeId === episodeId && isPlaying

  const handlePlayPause = useCallback(() => {
    if (episodeIsPlaying) {
      dispatch(setIsPlaying(false))
      return
    }

    dispatch(setCurrentEpisodeId(episodeId))
    dispatch(setEpisodeCurrentProgress(episodeId, 0))
    dispatch(setIsPlaying(true))
  }, [episodeIsPlaying, dispatch, episodeId])

  if (!episode) {
    return null
  }

  const {
    title,
    slug,
    speakers,
    description,
    publishedOn,
    tags,
    image,
    episodeNumber,
    applePodcastsLink,
    googlePodcastsLink,
    spotifyLink,
    iHeartRadioLink,
  } = episode

  return (
    <>
      <EpisodeContainer className={className}>
        <Episode>
          <EpisodeNumber>{`Ep. ${episodeNumber}`}</EpisodeNumber>
        </Episode>
        <Divider />
      </EpisodeContainer>
      <StyledPodcastListItem>
        <SquareImage image={image}>
          <ImageOverlay onClick={handlePlayPause}>
            <PlayIcon>
              {episodeIsPlaying ? <GiPauseButton /> : <BsFillCaretRightFill />}
            </PlayIcon>
          </ImageOverlay>
        </SquareImage>
        <Info>
          <StyledLink to={`/episode/${slug}`}>
            <h3>{title}</h3>
          </StyledLink>
          {speakers && (
            <div>{speakers.map((speaker) => speaker.name).join(', ')}</div>
          )}
          {description && <p>{description.description}</p>}
          <StyledPostInfo
            publishedOn={publishedOn}
            tags={tags}
            episodeDuration={episodeDuration}
          />
          <Row>
            <PodcastLinks
              applePodcastsLink={applePodcastsLink}
              googlePodcastsLink={googlePodcastsLink}
              spotifyLink={spotifyLink}
              iHeartRadioLink={iHeartRadioLink}
            />
            <Share
              shareTitle={title}
              shareLink={
                typeof window !== 'undefined'
                  ? `${window.location.protocol}//${window.location.host}/episode/${episodeNumber}`
                  : ''
              }
            />
          </Row>
        </Info>
      </StyledPodcastListItem>
    </>
  )
}

export default PodcastListItem
